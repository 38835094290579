import React from 'react';
import { useLocation } from 'react-router-dom';

import { Tabs } from './components';
import { Logo } from './images';
import { twjoin } from './utils';
import { paths } from '../../utils/routeUtils';
import { SignUp } from './pages';
import { LandingPageProps } from './pages/SignUp';
import { Banner } from '../Banners';

import { PiCheckBold, PiThumbsUp } from 'react-icons/pi';
import { useAuthModes } from '../../hooks';

const onClickLogo = () => {
  if (window?.open) {
    window.open('https://hasura.io/', '_blank', 'noopener')?.focus();
  }
};
const NoCreditCardIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
  >
    <g id="credit-card-x">
      <path
        id="Icon"
        d="M10.9987 10.6667L14.332 14M14.332 10.6667L10.9987 14M14.6654 6.66671H1.33203M14.6654 8.00004V5.46671C14.6654 4.71997 14.6654 4.3466 14.52 4.06139C14.3922 3.81051 14.1882 3.60653 13.9374 3.4787C13.6521 3.33337 13.2788 3.33337 12.532 3.33337H3.46537C2.71863 3.33337 2.34526 3.33337 2.06004 3.4787C1.80916 3.60653 1.60519 3.8105 1.47736 4.06139C1.33203 4.3466 1.33203 4.71997 1.33203 5.46671V10.5334C1.33203 11.2801 1.33203 11.6535 1.47736 11.9387C1.60519 12.1896 1.80916 12.3936 2.06004 12.5214C2.34526 12.6667 2.71863 12.6667 3.46536 12.6667H7.9987"
        stroke="#6C737F"
        stroke-width="1.2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
  </svg>
);

const V3SignUpHeader = ({
  children,
}: {
  children: React.ReactNode;
}): JSX.Element => {
  return (
    <h5 className="font-inter text-[20px] leading-[32px] font-semibold mb-4 self-center">
      {children}
    </h5>
  );
};

const DdnLoginPage = (props: LandingPageProps): JSX.Element => {
  const { pathname } = useLocation();

  const showSignInContentHeading = React.useMemo(() => {
    return (
      pathname !== paths.v3.register.newUserCloud() &&
      pathname !== paths.v3.login.forgotPassword()
    );
  }, [pathname]);

  const showSignUpContentHeading = React.useMemo(() => {
    return (
      pathname !== paths.v3.login.forgotPassword() &&
      pathname === paths.v3.register.newUserCloud()
    );
  }, [pathname]);

  const CheckBadge = () => {
    return (
      <div className="flex items-center justify-center rounded-full w-6 h-6 p-1 bg-[#DFE8FF] text-[#0D41C6]">
        <PiCheckBold className="w-6 h-6" />
      </div>
    );
  };
  type Feature = {
    Icon: React.ComponentType;
    text: string;
  };
  const features: Feature[] = [
    {
      Icon: CheckBadge,
      text:
        'Iterate fast with instant previews, local dev, robust CLI, and no cold start.',
    },
    {
      Icon: CheckBadge,
      text:
        'Simplify full API lifecycle with a declarative metadata-driven approach.',
    },
    {
      Icon: CheckBadge,
      text: 'Deliver speed and composability, with minimal work.',
    },
  ];

  const { allowedAuthModes } = useAuthModes();

  const getValidPathName = () => {
    if (
      pathname === paths.v3.register.newUserCloud() ||
      pathname === paths.v3.login.forgotPassword()
    )
      return pathname;

    if (!allowedAuthModes.length) return null;

    const isCurrentPathNameAllowed = !!allowedAuthModes.find(
      a => a.route === pathname
    );

    if (isCurrentPathNameAllowed) return pathname;

    const firstValidPathname = allowedAuthModes[0].route;

    return firstValidPathname;
  };

  const validPathName = getValidPathName();

  const FeatureItem = ({ feature }: { feature: Feature }) => {
    return (
      <div className="self-stretch justify-start items-start gap-3 inline-flex text-xs sm:text-xs md:text-xs lg:text-xs xl:text-md">
        <feature.Icon />
        <div className="grow shrink basis-0 text-[#18222f] font-normal font-['Inter'] leading-normal py-1">
          {feature.text}
        </div>
      </div>
    );
  };
  const AuthSection = () => (
    <div className=" p-2 bg-gray-50 rounded-tr-3xl rounded-br-3xl flex-col justify-center items-center gap-6 inline-flex h-full">
      <div
        className={twjoin(
          'bg-white h-full w-full',
          'm-6 md:m-6 xl:mx-16 xl:mt-16 xl:mb-0',
          'flex flex-col md:max-w-lg border border-v3-menuBorder p-4 ',
          'rounded-2xl md:justify-start justify-center min-h-0',
          pathname === paths.v3.register.newUserCloud()
            ? 'lg:px-16 lg:py-3'
            : 'lg:p-16'
        )}
      >
        {showSignInContentHeading ? (
          <V3SignUpHeader>Sign in to your account</V3SignUpHeader>
        ) : null}
        {showSignUpContentHeading ? (
          <V3SignUpHeader>Create a new Hasura Account</V3SignUpHeader>
        ) : null}
        {validPathName ? (
          <>
            {showSignInContentHeading ? (
              <Tabs currentRoute={validPathName} />
            ) : null}
            <SignUp currentRoute={validPathName} landingProps={props} />
          </>
        ) : (
          <div className="px-4 py-3 text-sm rounded-md bg-red-100 justify-start items-center gap-2">
            No <code className="display-inline">AUTH_METHODS</code> detected in
            your configuration.
            <br />
            Please contact you administrator
          </div>
        )}
      </div>
      <div className="justify-start items-start gap-6 inline-flex mb-1 lg:my-4">
        {[
          {
            text: 'Generous free tier',
            Icon: PiThumbsUp,
          },
          {
            text: 'No credit card required',
            Icon: NoCreditCardIcon,
          },
        ].map(({ text, Icon }) => (
          <div className="h-6 justify-start items-center gap-6 flex">
            <div className="justify-start items-center gap-1 flex">
              <div className="p-1 rounded-[32px] justify-center items-center gap-1 flex">
                <Icon />
              </div>
              <div className="text-[#6c727e] text-sm font-medium font-['Inter'] leading-tight">
                {text}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );

  const ContentSection = () => (
    <div
      className={twjoin(
        'flex w-full justify-between flex-col md:w-3/5 items-center md:items-start',
        'mb-5 md:mb-0',
        'md:p-4 xl:p-16 p-4'
      )}
    >
      <div className="hover:cursor-pointer" onClick={onClickLogo}>
        <Logo />
      </div>
      <div className="self-stretch flex-col justify-start items-start gap-10 hidden 1080p:flex ">
        <div className="self-stretch flex-col justify-start items-start gap-6 flex">
          <div className="flex flex-col items-center mt-4 md:mt-0 md:items-start md:flex-row md:block">
            <div className="justify-start items-center gap-2 inline-flex">
              <div className="text-[#000615] font-semibold font-['Inter'] leading-loose">
                <h1 className="text-lg mt-4">
                  Ship a fast, secure, and robust API on all your data in just
                  minutes.
                </h1>
              </div>
            </div>
          </div>
          <div className="self-stretch h-44 flex-col justify-start items-start gap-4 flex">
            {features.map((feature, index) => (
              <FeatureItem key={index} feature={feature} />
            ))}
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <div
      className="bg-v3-background w-screen flex items-center justify-center flex-col md:h-screen"
      id="new-sign-up-page-root"
    >
      <Banner />
      <div
        className={twjoin(
          'font-inter',
          'flex bg-white md:rounded-[24px] shadow-md justify-between flex-col w-screen lg:w-4/5 md:w-[90%] mt-0 1080p:flex-row max-w-[1280px] max-h-[900px]',
          pathname === paths.v3.register.newUserCloud()
            ? 'md:h-[90%]'
            : 'md:h-[85%]'
        )}
      >
        <ContentSection />
        <AuthSection />
      </div>
    </div>
  );
};

export default DdnLoginPage;
