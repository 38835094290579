import React from 'react';
import { useLocation, Link, useNavigate } from 'react-router-dom';
import { AuthState } from '../../../AuthCheck';
import { paths } from '../../../utils/routeUtils';
import {
  ForgotPassword,
  RegisterEmailPassword,
  SSOSignIn,
  SignInEmailPassword,
  SocialSignIn,
} from '../forms';
import { ProfileIcon } from '../images';

export interface LandingPageProps extends AuthState {
  loginCallback: (loginRedirectState: unknown) => void;
  isLoggedIn: boolean;
}

interface SignUpProps {
  currentRoute: string;
  landingProps: LandingPageProps;
}

const SignUp = ({ currentRoute, ...props }: SignUpProps): JSX.Element => {
  const { search } = useLocation();
  const navigate = useNavigate();

  // NOTE:
  // If we locate the search params to be:
  // redirect_url=%2Foauth2-error%3Ferror_reason%3SAML%2FSSO%2Bis%2Benabled%2Bfor%2Bthe%2Buser
  // or
  // redirect_url=%2Foauth2-error%3Ferror_reason%3Derror%2Bwith%2Blog%2Bin%2Bor%2Bregister
  //
  // Then it is most likely the case that we have
  // encountered an user that is trying to use oauth for their
  // email but their ORG already has SSO enabled.
  //
  // So, we'd be helping them with a better error message
  // to help them navigate to the SSO tab.
  const ssoErrState = React.useMemo(() => {
    const searchParams = new URLSearchParams(
      search.substring(search.indexOf('?'))
    );
    const redirectUrlValue = searchParams.get('redirect_url');

    if (
      redirectUrlValue !== null &&
      (redirectUrlValue ===
        '/oauth2-error?error_reason=SAML/SSO+is+enabled+for+the+user' ||
        redirectUrlValue.includes('SAML') ||
        redirectUrlValue.includes('SSO') ||
        redirectUrlValue ===
          '/oauth2-error?error_reason=error+with+log+in+or+register')
    ) {
      return true;
    } else {
      return false;
    }
  }, [search]);

  React.useEffect(() => {
    // redirect the user if they end up on this page but are authenticated users
    if (props.landingProps.isLoggedIn && props.landingProps.isAuthenticated) {
      navigate({
        pathname: paths.projects(),
        search,
      });
    }
  }, [props.landingProps.isAuthenticated, props.landingProps.isLoggedIn]);

  const pageContent = React.useMemo(() => {
    switch (currentRoute) {
      case paths.signup():
        return <SocialSignIn ssoError={ssoErrState} />;
      case paths.v3.login.email():
        return (
          <SignInEmailPassword
            loginCallback={props.landingProps.loginCallback}
          />
        );
      case paths.v3.login.sso():
        return <SSOSignIn />;
      case paths.v3.register.newUserCloud():
        return <RegisterEmailPassword />;
      case paths.v3.login.forgotPassword():
        return <ForgotPassword />;
      default:
        return <SocialSignIn ssoError={ssoErrState} />;
    }
  }, [currentRoute]);

  return (
    <div className="flex h-full flex-col justify-between min-h-0">
      {pageContent}
      <div className="flex flex-row p-1 mt-3 justify-center 1080p:justify-center">
        <ProfileIcon />
        <span className="flex flex-row items-center font-inter font-medium text-base">
          {currentRoute !== paths.v3.register.newUserCloud() ? (
            <Link
              to={{ pathname: paths.v3.register.newUserCloud(), search }}
              className="text-v3-footer-link hover:cursor-pointer"
            >
              Register
            </Link>
          ) : null}
          {currentRoute === paths.v3.register.newUserCloud() ? (
            <Link
              to={{ pathname: paths.signup(), search }}
              className="text-v3-footer-link hover:cursor-pointer"
            >
              Sign In
            </Link>
          ) : null}
          {currentRoute !== paths.v3.register.newUserCloud() ? (
            <span className="text-v3-footer-text ml-1 truncate">
              for a new account
            </span>
          ) : null}
          {currentRoute === paths.v3.register.newUserCloud() ? (
            <>
              <span className="text-v3-footer-text ml-1 truncate hidden md:block">
                to an existing account
              </span>
              <span className="text-v3-footer-text ml-1 truncate md:hidden block">
                to your account
              </span>
            </>
          ) : null}
        </span>
      </div>
    </div>
  );
};

export default SignUp;
